import { compose } from '@xo-union/react-css-modules';
import { Form, Textarea } from '@xo-union/tk-component-fields';
import {
	RadioButton,
	SingleSelectGroup,
} from '@xo-union/tk-component-switches';
import { Body1, Caption, H4 } from '@xo-union/tk-ui-typography';
import { useAtom, useAtomValue } from 'jotai';
import React, { ChangeEvent, useCallback } from 'react';
import {
	contentAtom,
	isValidRateVendorAtom,
	knotHelpedHireAtom,
	ratingAtom,
} from '../../atoms';
import {
	AdditionalInteractionProperties,
	ReviewFormInteraction,
} from '../../types';
import { SubmitButton } from '../submit-button';
import { StarRating } from './components/star-rating';

import Styles from './styles.scss';

const textAreaClasses = compose({
	container: Styles.textAreaContainer,
});

export interface RateVendorStepProps {
	handleClick: () => void;
	handleTrackReviewFormInteraction: (
		action: ReviewFormInteraction,
		additionalProperties: AdditionalInteractionProperties,
	) => void;
	isMobile?: boolean;
}

const RateVendorStep = ({
	handleClick,
	handleTrackReviewFormInteraction,
	isMobile = false,
}: RateVendorStepProps) => {
	const [content, setContent] = useAtom(contentAtom);
	const [rating, setRating] = useAtom(ratingAtom);
	const [knotHelpedHire, setKnotHelpedHire] = useAtom(knotHelpedHireAtom);
	const isValidRateVendor = useAtomValue(isValidRateVendorAtom);

	const onStarClick = useCallback(
		(selectedRating: number) => {
			handleTrackReviewFormInteraction('select star', {
				starValue: selectedRating,
			});
			setRating(selectedRating);
		},
		[handleTrackReviewFormInteraction, setRating],
	);

	const handleContent = useCallback(
		(e: ChangeEvent<HTMLTextAreaElement>) => {
			setContent(e.target.value);
		},
		[setContent],
	);

	const REVIEW_MODAL_TEXTAREA_TOP_PX = 380;

	const handleTextareaClick = () => {
		// eslint-disable-next-line no-unused-expressions
		isMobile &&
			window.addEventListener(
				'resize',
				() =>
					document
						.querySelector('div[role="dialog"]')
						?.scrollTo(0, REVIEW_MODAL_TEXTAREA_TOP_PX),
				{ once: true },
			);
	};

	return (
		<Form onSubmit={handleClick}>
			<div className={Styles.introContainer}>
				<H4 className={Styles.introHeader}>Share your experience</H4>
				<Body1 className={Styles.introContent}>
					We value honest feedback about our vendors from couples who booked
					their services.
				</Body1>
				<Caption>* = Required</Caption>
			</div>
			<div className={Styles.rateContainer}>
				<Body1 className={Styles.rateTitle}>Rate the vendor*</Body1>
				<StarRating onClick={onStarClick} value={rating} />
			</div>
			<div className={Styles.writeContainer}>
				<Body1 className={Styles.writeTitle}>Write your review*</Body1>
				{/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
				<div onClick={handleTextareaClick}>
					<Textarea
						classes={textAreaClasses}
						value={content}
						onChange={handleContent}
						name="content"
						label={false}
						maxLength={3500}
						placeholder="Details about professionalism, communications, quality of service, and value are especially helpful for other couples."
						rows="5"
					/>
				</div>
			</div>
			<div className={Styles.radioContainer}>
				<Body1 className={Styles.radioTitle}>
					Did The Knot help you to hire this vendor?*
				</Body1>
				<SingleSelectGroup
					value={knotHelpedHire}
					onChange={(e) => setKnotHelpedHire(e.target.value)}
				>
					<RadioButton value="Yes" data-testid="help-Yes">
						Yes
					</RadioButton>
					<RadioButton value="No" data-testid="help-No">
						No
					</RadioButton>
				</SingleSelectGroup>
			</div>
			<SubmitButton disabled={!isValidRateVendor}>Next</SubmitButton>
		</Form>
	);
};

export { RateVendorStep };
