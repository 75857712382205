import { isReviewsLightboxAtom } from '@components/lightbox/atoms';
import AnalyticsConstants from '@constants/analytics/index.js';
import useAnalyticsContext from '@hooks/useAnalyticsContext';
import { useAtomValue } from 'jotai';
import { t } from 'msw/lib/SetupApi-f4099ef3';
import { useCallback, useMemo } from 'react';
import VendorAnalytics from '../../../decorators/vendorAnalytics';

export type LightboxActionType =
	| typeof AnalyticsConstants.SWIPE_RIGHT
	| typeof AnalyticsConstants.SWIPE_LEFT
	| typeof AnalyticsConstants.PLAY_TOUR
	| typeof AnalyticsConstants.DISMISS
	| typeof AnalyticsConstants.FILTER_CLICKED
	| typeof AnalyticsConstants.ADD_TO_FAVORITE
	| typeof AnalyticsConstants.QUOTE_REQUESTED
	| typeof AnalyticsConstants.SEE_ALL_IMAGES;

export type LightboxEventType =
	| typeof AnalyticsConstants.VENDOR_PORTFOLIO_INTERACTION
	| typeof AnalyticsConstants.ADD_TO_FAVORITE;

export type LightboxSourceContentType =
	| typeof AnalyticsConstants.PHOTO_GALLERY
	| typeof AnalyticsConstants.PHOTO_DETAIL_VIEW
	| typeof AnalyticsConstants.portfolioType.REVIEWS;

const useTrackUnifiedLightbox = (vendor: Vendor.Decorated) => {
	const { track } = useAnalyticsContext();

	return useCallback(
		(
			event: LightboxEventType,
			action: LightboxActionType,
			sourceContent: LightboxSourceContentType,
		) => {
			const paidStatus = vendor.isPaid ? 'paid' : 'unpaid';
			const vendorAnalytics = new VendorAnalytics(vendor);
			track({
				event,
				properties: {
					action,
					sourcePage: `${paidStatus} storefront`,
					sourceContent,
					...vendorAnalytics.baseEventProps(),
				},
			});
		},
		[track, vendor],
	);
};

export default useTrackUnifiedLightbox;
