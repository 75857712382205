import { useAppDispatch, useAppSelector } from '@redux/hooks';
import { registerAndLogUserWithRandomPassword } from '@redux/reviews/utils';
import { compose } from '@xo-union/react-css-modules';
import { Field, Form, PseudoEvent } from '@xo-union/tk-component-fields';
import { FormColumn, FormRow } from '@xo-union/tk-component-form-grid';
import MembershipModalRedux, {
	OpenModalType,
} from '@xo-union/tk-component-membership-modal-redux';
import { Body1, Body2, Caption, H4 } from '@xo-union/tk-ui-typography';
import { useAtom, useAtomValue } from 'jotai';
import React, { FC, createRef, useEffect, useMemo, useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import {
	emailAtom,
	firstNameAtom,
	isValidAuthenticatedUserInfoAtom,
	isValidUnauthenticatedUserInfoAtom,
	lastNameAtom,
} from '../../atoms';
import {
	HandleLogInModalClosed,
	HandleSubmitWithSignUpOrLogin,
} from '../../hooks/useReviewModalSteps';
import { SubmitButton } from '../submit-button';
import Styles from './styles.scss';

export interface Props {
	isLoggedIn: boolean;
	isSubmitting: boolean;
	handleClick: () => void;
	handleSubmitWithSignUpOrLogin: HandleSubmitWithSignUpOrLogin;
	handleLogInModalClosed: HandleLogInModalClosed;
}

const classes = compose({
	input: 'fs-mask',
});

const UserInfoWithAutosignupStep: FC<Props> = (props) => {
	const {
		isLoggedIn,
		isSubmitting,
		handleClick,
		handleSubmitWithSignUpOrLogin,
		handleLogInModalClosed,
	} = props;

	const [pendingSubmit, setPendingSubmit] = useState(false);

	const queueReviewSubmit = () => {
		setPendingSubmit(true);
	};

	useEffect(() => {
		if (pendingSubmit) {
			handleClick();
			setPendingSubmit(false);
		}
	}, [pendingSubmit, handleClick]);

	const dispatch = useAppDispatch();

	const [firstName, setFirstName] = useAtom(firstNameAtom);
	const [lastName, setLastName] = useAtom(lastNameAtom);
	const [email, setEmail] = useAtom(emailAtom);

	const isValidAuthenticatedUserInfo = useAtomValue(
		isValidAuthenticatedUserInfoAtom,
	);
	const isValidUnauthenticatedUserInfo = useAtomValue(
		isValidUnauthenticatedUserInfoAtom,
	);

	const isValidUserInfo = useMemo(
		() =>
			isLoggedIn
				? isValidAuthenticatedUserInfo
				: isValidUnauthenticatedUserInfo,
		[isLoggedIn, isValidAuthenticatedUserInfo, isValidUnauthenticatedUserInfo],
	);

	const recaptchaRef = createRef<ReCAPTCHA>();
	const [openModal, setOpenModal] = useState<OpenModalType | undefined>();

	const startSubmitWithSignUpOrLogin = async () => {
		await handleSubmitWithSignUpOrLogin(
			isLoggedIn,
			email,
			queueReviewSubmit,
			() => setOpenModal('LOG_IN'),
			() =>
				dispatch(
					registerAndLogUserWithRandomPassword(email, firstName, lastName),
				) as unknown as Promise<void>,
		);
	};

	const onSubmit = async (pseudoEvent: PseudoEvent) => {
		if (!pseudoEvent.valid) return;

		if (isLoggedIn) {
			await startSubmitWithSignUpOrLogin();
		} else {
			recaptchaRef.current?.execute();
		}
	};

	const closeModal = (loggedIn: boolean) => {
		handleLogInModalClosed(
			loggedIn,
			() => setOpenModal(undefined),
			queueReviewSubmit,
		);
	};

	return (
		<>
			<Form
				onSubmit={onSubmit}
				className={Styles.userInfoForm}
				data-testid="autosignup-step"
			>
				<div className={Styles.introContainer}>
					<H4 className={Styles.introHeader}>Your info</H4>
					<Caption>* = Required</Caption>
				</div>
				<div className={Styles.inputWrapper}>
					<div className={Styles.inputContainer}>
						<Body1>First and last name*</Body1>
						<Body2 className={Styles.note} as="p">
							Only your first name and last initial will be displayed with your
							rating, review, and photos.
						</Body2>
						<FormRow className={Styles.formRow}>
							<FormColumn className={Styles.firstNameCol} xs>
								<Field
									classes={classes}
									name="firstName"
									value={firstName}
									label="First name"
									validations={[{ required: true }]}
									onChange={(e) => setFirstName(e.target.value)}
								/>
							</FormColumn>
							<FormColumn className={Styles.formCol} xs>
								<Field
									classes={classes}
									name="lastName"
									value={lastName}
									label="Last name"
									validations={[{ required: true }]}
									onChange={(e) => setLastName(e.target.value)}
								/>
							</FormColumn>
						</FormRow>
					</div>
					{!isLoggedIn && (
						<>
							<ReCAPTCHA
								size="invisible"
								ref={recaptchaRef}
								sitekey="6Ldes2cUAAAAAJ1uLOELj0XfySPk6dcJMxdpJARS"
								onChange={() => startSubmitWithSignUpOrLogin()}
							/>
							<div className={Styles.inputContainer}>
								<Body1 className={Styles.emailTitle}>Email*</Body1>
								<FormRow className={Styles.formRow}>
									<FormColumn className={Styles.formCol} xs>
										<Field
											classes={classes}
											name="email"
											type="email"
											value={email}
											validations={[{ required: true, email: true }]}
											onChange={(e) => setEmail(e.target.value)}
										/>
									</FormColumn>
								</FormRow>
							</div>
						</>
					)}
				</div>
				<div className={Styles.privacy}>
					<Body2 className={Styles.note} as="p">
						By clicking “Submit your review,” you agree to The Knot&apos;s&nbsp;
						<a
							href="https://www.theknotww.com/privacy-policy"
							rel="noopener noreferrer"
							target="_blank"
						>
							Privacy Policy
						</a>
						&nbsp;and&nbsp;
						<a
							href="https://www.theknotww.com/terms-of-use"
							rel="noopener noreferrer"
							target="_blank"
						>
							Terms of Use
						</a>
					</Body2>
				</div>
				<SubmitButton
					disabled={!isValidUserInfo}
					type="submit"
					isLoading={isSubmitting}
					loadingText="Submit your review"
				>
					Submit your review
				</SubmitButton>
			</Form>
			<MembershipModalRedux
				metadata={{
					userAction: 'review login',
					applicationName: 'marketplace',
				}}
				openModalType={openModal}
				onClickLogIn={() => setOpenModal('LOG_IN')}
				onClickSignUp={() => setOpenModal('SIGN_UP')}
				onSignUpSuccess={() => closeModal(true)}
				onLogInSuccess={() => closeModal(true)}
				onClose={() => closeModal(false)}
			/>
		</>
	);
};

export { UserInfoWithAutosignupStep };
