import {
	computedMediaAtom,
	currentFilterAtom,
	currentIndexAtom,
	nextMediaAtom,
	prevMediaAtom,
} from '@components/unified-lightbox/atoms';
import useTrackUnifiedLightbox from '@components/unified-lightbox/hooks/use-track-unified-lightbox';
import AnalyticsConstants from '@constants/analytics/index.js';
import { useScreenDetails } from '@hooks/useScreenDetails';
import noop from '@utils/noop';
import { Carousel } from '@xo-union/ui-carousel';
import { useAtomValue, useSetAtom } from 'jotai';
import React, { FC, useCallback } from 'react';
import { connect } from 'react-redux';
import Media from '../../../../pages/Storefront/components/Media';
import Playable, {
	PlayableRenderProps,
} from '../../../../pages/Storefront/components/Media/playable';
import useCarouselSize from '../../../../pages/Storefront/hooks/useCarouselSize';
import ReviewDetails from '../ReviewDetails/ReviewDetails';
import {
	getCarouselStyles,
	getImageStyles,
	getLightboxWrapperStyles,
} from './utils';

interface StateProps {
	vendor: Vendor.Decorated;
	vendorRaw: Vendor.Raw | null;
}

export type CarouselMobileProps = StateProps;

const CarouselMobile: FC<CarouselMobileProps> = (props) => {
	const { vendor, vendorRaw } = props;
	const currentFilter = useAtomValue(currentFilterAtom);
	const isReviewsLightbox = currentFilter === 'reviews';

	const carouselMedia = useAtomValue(computedMediaAtom);
	const currentIndex = useAtomValue(currentIndexAtom);
	const prevImage = useSetAtom(prevMediaAtom);
	const nextImage = useSetAtom(nextMediaAtom);

	const [isLandscape, width, height] = useScreenDetails();
	const trackUnifiedLightbox = useTrackUnifiedLightbox(vendor);

	const onNavigationChange = useCallback(
		(index) => {
			if (currentIndex < index) {
				trackUnifiedLightbox(
					AnalyticsConstants.VENDOR_PORTFOLIO_INTERACTION,
					AnalyticsConstants.SWIPE_RIGHT,
					AnalyticsConstants.PHOTO_DETAIL_VIEW,
				);
				nextImage(noop);
			} else {
				trackUnifiedLightbox(
					AnalyticsConstants.VENDOR_PORTFOLIO_INTERACTION,
					AnalyticsConstants.SWIPE_LEFT,
					AnalyticsConstants.PHOTO_DETAIL_VIEW,
				);
				prevImage(noop);
			}
		},
		[trackUnifiedLightbox, nextImage, prevImage, currentIndex],
	);

	const responsive = useCallback(() => ({ slides: 1 }), []);

	return (
		<Carousel
			classes={getCarouselStyles(isLandscape)}
			gutter={0}
			responsive={responsive}
			navigationIndex={currentIndex}
			onNavigationIndexChange={onNavigationChange}
			{...useCarouselSize()}
		>
			{carouselMedia.map((media) => {
				const isSingleItem = carouselMedia.length === 1;

				const { image } = getImageStyles(media, isReviewsLightbox);
				const isQuickResponder = Boolean(
					vendorRaw?.vendorBehavior?.quickResponder,
				);

				return (
					<div
						className={getLightboxWrapperStyles(media, isQuickResponder)}
						key={media.id}
					>
						<Playable
							media={media}
							render={({ playMedia }: PlayableRenderProps) => {
								const { mediaType } = media;
								if (
									isSingleItem &&
									(mediaType === '360TOUR' || mediaType === 'VIDEO')
								) {
									playMedia();
								}
								return (
									<>
										<Media
											canPlay
											mediaItem={media}
											imageClass={image}
											width={width}
											height={height}
											fit
											tabIndexOverride={-1}
										/>
										{isReviewsLightbox && <ReviewDetails />}
									</>
								);
							}}
						/>
					</div>
				);
			})}
		</Carousel>
	);
};

const mapStateToProps = (state: Redux.State): StateProps => ({
	vendor: state.vendor.vendor as Vendor.Decorated,
	vendorRaw: state.vendor.vendorRaw,
});

// biome-ignore lint/complexity/noBannedTypes: <explanation>
export default connect<StateProps, {}>(mapStateToProps)(CarouselMobile);
