import { isMediaOfType, isPhotoWide } from '@utils/mediaUtils';
import { compose } from '@xo-union/react-css-modules';
import classnames from 'classnames';
import Styles from '../CarouselMobile.scss';

const getCarouselStyles = (isLandscape: boolean) => {
	if (!isLandscape) {
		return undefined;
	}

	return compose({
		relativeContainer: Styles.lightboxCarouselLandscape,
	});
};

const getPhotoStyles = (
	media: Vendor.PhotoMedia,
	isReviewsLightbox: boolean,
) => {
	if (isReviewsLightbox) {
		return {
			image: Styles.lightboxImageReviews,
		};
	}
	if (isPhotoWide(media)) {
		return {
			image: classnames(Styles.lightboxImage, Styles.lightboxImageWide),
		};
	}
	return {
		image: classnames(Styles.lightboxImage, Styles.lightboxImageTall),
	};
};

const getImageStyles = (media: Vendor.Media, isReviewsLightbox = false) => {
	if (isMediaOfType<Vendor.PhotoMedia>(media, 'PHOTO')) {
		return getPhotoStyles(media, isReviewsLightbox);
	}
	return { image: '' };
};

const getLightboxWrapperStyles = (
	media: Vendor.Media,
	isQuickResponder?: boolean,
) => {
	const DEFAULT_MEDIA_WRAPPER_STYLES = Styles.lightboxMediaWrapper;

	if (isMediaOfType<Vendor.PhotoMedia>(media, 'PHOTO') && !isPhotoWide(media)) {
		return classnames(
			DEFAULT_MEDIA_WRAPPER_STYLES,
			Styles.lightboxMediaWrapperTall,
		);
	}

	if (isMediaOfType<Vendor.TourMedia>(media, '360TOUR') && isQuickResponder) {
		return classnames(
			DEFAULT_MEDIA_WRAPPER_STYLES,
			Styles.lightboxMediaWrapperForTour,
		);
	}

	return classnames(DEFAULT_MEDIA_WRAPPER_STYLES);
};

export {
	isPhotoWide,
	getCarouselStyles,
	getPhotoStyles,
	getImageStyles,
	getLightboxWrapperStyles,
};
