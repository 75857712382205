import { uploadPhoto } from '@api/media';
import {
	internalCreateImageReviewsUrl,
	internalDeleteImageReviewsUrl,
} from '@settings';
import {
	HandleClientSideFileInputParams,
	HandleUploadParams,
	MediaApiResponse,
	RetryUploadParams,
	UploadedMediaState,
} from '../types';

const THIRTY_MB_IN_DECIMAL = 30000000;

const createImages = (file: File) => {
	const img = document.createElement('img');
	img.src = URL.createObjectURL(file);
	return img;
};

const filterByFileSize = (files: File[]) =>
	files.filter((file) => file.size <= THIRTY_MB_IN_DECIMAL);

const handleRetry = async ({
	file,
	memberId,
	setUploadedMedia,
	vendorId,
	id,
}: RetryUploadParams) => {
	setUploadedMedia((prevState) => {
		const nextState = prevState.slice();
		const mediaToRetry = nextState.find((s) => s.id === id);
		if (mediaToRetry) {
			mediaToRetry.isUploaded = false;
			return [...nextState];
		}
		return [...prevState];
	});

	const body = new FormData();
	body.append('file', file, file.name);

	const ownersIds = [vendorId as string, memberId as string];
	const joinedIds = ownersIds.join(';');
	body.append('ownerIds', joinedIds);

	const internalRoute: Response = await fetch(internalCreateImageReviewsUrl, {
		method: 'post',
	});
	const mediaJwt = await internalRoute.text();
	const response: Response = await uploadPhoto(mediaJwt, file, [
		vendorId as string,
		memberId as string,
	]);
	const jsonResponse: MediaApiResponse = await response.json();
	const mediaId = jsonResponse.id;

	setUploadedMedia((prevState) => {
		const nextState = prevState.slice();
		const mediaToRetry = nextState.find((s) => s.id === id);
		if (mediaToRetry) {
			mediaToRetry.isUploaded = true;
			mediaToRetry.serverReturnedMediaId = mediaId;
			mediaToRetry.isErrored = response.status !== 200;
			return [...nextState];
		}
		return [...prevState];
	});
};

const handleUpload = async ({
	files,
	imageIds,
	member,
	setUploadedMedia,
	vendor,
}: HandleUploadParams) => {
	const allowedFiles = filterByFileSize(files);

	await Promise.allSettled(
		allowedFiles.map(async (file, idx) => {
			const body = new FormData();
			body.append('file', file, file.name);

			const ownersIds = [vendor?.id as string, member?.id as string];
			const joinedIds = ownersIds.join(';');
			body.append('ownerIds', joinedIds);

			const internalRoute: Response = await fetch(
				internalCreateImageReviewsUrl,
				{ method: 'post' },
			);
			const mediaJwt = await internalRoute.text();
			const response: Response = await uploadPhoto(mediaJwt, file, [
				vendor?.id as string,
				member?.id as string,
			]);
			const jsonResponse: MediaApiResponse = await response.json();
			const mediaId = jsonResponse.id;

			setUploadedMedia((prevState) => {
				const nextState = prevState.slice();
				const index = nextState.findIndex((s) => s.id === imageIds[idx]);
				nextState[index].isUploaded = true;
				nextState[index].serverReturnedMediaId = mediaId;
				nextState[index].isErrored = response.status !== 200;
				return [...nextState];
			});

			return response;
		}),
	);
};

const handleClientSideFileInput = ({
	e,
	member,
	setUploadedMedia,
	vendor,
}: HandleClientSideFileInputParams) => {
	if (!e.target.files) {
		return;
	}

	if (e.target.files) {
		const currentFiles: File[] = Array.from(e.target.files);
		const currentImages = currentFiles.map(createImages);
		const imageIds = currentImages.map((c) => c.src.split('com/')[1]);

		const payload: UploadedMediaState[] = currentFiles.map((file, idx) => {
			return {
				fileData: file,
				id: imageIds[idx],
				imageData: createImages(file),
				isCorrectSize: file.size <= THIRTY_MB_IN_DECIMAL,
				isUploaded: false,
				serverReturnedMediaId: '',
				isErrored: false,
			};
		});

		setUploadedMedia((prevState) => {
			return [...prevState, ...payload];
		});

		handleUpload({
			files: currentFiles,
			imageIds,
			member,
			setUploadedMedia,
			vendor,
		});
	}
};

const isEveryPhotoCorrectSize = (uploadedMedia: UploadedMediaState[]) =>
	uploadedMedia.every((media) => media.isCorrectSize);

const isAnyPhotoErrored = (uploadedMedia: UploadedMediaState[]) =>
	uploadedMedia.some((media) => media.isErrored);

const deleteImage = async (media: UploadedMediaState) => {
	const headers = new Headers();
	headers.append('photoId', media.serverReturnedMediaId);
	const result = await fetch(internalDeleteImageReviewsUrl, {
		method: 'delete',
		headers,
	});
	const jsonResult = await result.json();

	return jsonResult;
};

export {
	createImages,
	filterByFileSize,
	handleClientSideFileInput,
	handleRetry,
	deleteImage,
	handleUpload,
	isEveryPhotoCorrectSize,
	isAnyPhotoErrored,
	THIRTY_MB_IN_DECIMAL,
};
