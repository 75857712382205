import {
	computedMediaAtom,
	currentIndexAtom,
	currentOrientationAtom,
	nextMediaAtom,
	prevMediaAtom,
} from '@components/unified-lightbox/atoms';
import useTrackUnifiedLightbox from '@components/unified-lightbox/hooks/use-track-unified-lightbox';
import AnalyticsConstants from '@constants/analytics/index.js';
import { useAppSelector } from '@redux/hooks';
import { IconButton } from '@xo-union/tk-component-icons';
import classNames from 'classnames';
import { useAtomValue, useSetAtom } from 'jotai';
import React, { useCallback, useMemo } from 'react';
import { v4 as uuidv4 } from 'uuid';
import Styles from './carousel.scss';
import { useLightboxKeydown } from './hooks/use-lightbox-keydown';
import { MediaContainer } from './media-container';

const useCreateClick = (
	action: 'clicked right' | 'clicked left',
	onClick: (callback: () => void) => void,
) => {
	const vendor = useAppSelector(
		(state) => state.vendor.vendor as Vendor.Decorated,
	);
	const trackUnifiedLightbox = useTrackUnifiedLightbox(vendor);

	return useCallback(() => {
		onClick(() =>
			trackUnifiedLightbox(
				AnalyticsConstants.VENDOR_PORTFOLIO_INTERACTION,
				action,
				AnalyticsConstants.PHOTO_DETAIL_VIEW,
			),
		);
	}, [action, onClick, trackUnifiedLightbox]);
};

const NextButton = () => {
	const nextImage = useSetAtom(nextMediaAtom);
	const onClick = useCreateClick('clicked right', nextImage);
	const mediaItems = useAtomValue(computedMediaAtom);
	const index = useAtomValue(currentIndexAtom);

	return (
		<div className={Styles.iconWrapper} aria-roledescription="next button">
			<IconButton
				className={classNames(Styles.nextButton, {
					[Styles.hidden]: index >= mediaItems.length - 1,
				})}
				name="caret_right"
				onClick={onClick}
				size="md"
			/>
		</div>
	);
};

const PrevButton = () => {
	const prevImage = useSetAtom(prevMediaAtom);
	const onClick = useCreateClick('clicked left', prevImage);
	const index = useAtomValue(currentIndexAtom);

	return (
		<div className={Styles.iconWrapper} aria-roledescription="previous button">
			<IconButton
				className={classNames(Styles.previousButton, {
					[Styles.hidden]: index <= 0,
				})}
				name="caret_left"
				onClick={onClick}
				size="md"
			/>
		</div>
	);
};

export const CarouselDesktop = () => {
	const mediaItems = useAtomValue(computedMediaAtom);
	const index = useAtomValue(currentIndexAtom);
	const lightboxOrientation = useAtomValue(currentOrientationAtom);
	const id = useMemo(() => uuidv4(), []);

	useLightboxKeydown();

	return (
		<div
			className={classNames(Styles.carouselWrapper)}
			data-testid="carousel-wrapper"
			aria-label="carousel"
			aria-roledescription="carousel"
			aria-describedby={id}
			role="group"
		>
			<span
				aria-atomic="true"
				aria-live="assertive"
				className="sr-only"
				id={id}
				style={{ display: 'none' }}
			>
				{`Showing slide number ${index + 1} out of ${mediaItems.length}`}
			</span>
			<PrevButton />
			<div
				className={classNames(
					Styles.flex,
					Styles.flexWrap,
					Styles.justifyCenter,
					Styles.landscape,
					Styles.alignContentCenter,
				)}
			>
				<div
					className={classNames({
						[Styles.landscape]: lightboxOrientation === 'landscape',
						[Styles.portrait]: lightboxOrientation === 'portrait',
					})}
					data-testid="carousel-media-wrapper"
					role="presentation"
				>
					{mediaItems.map((item, idx) => {
						const key = `${idx}_carousel_${item.id}`;
						const visible = index === idx;
						return (
							<MediaContainer
								key={key}
								media={item}
								totalCount={mediaItems.length}
								index={idx}
								visible={visible}
								isSingleItem={mediaItems.length === 1}
							/>
						);
					})}
				</div>
			</div>
			<NextButton />
		</div>
	);
};
