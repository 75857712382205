import { atom } from 'jotai';
import {
	Gallery,
	ReviewFormSubmission,
	ReviewPhoto,
	UploadedMediaState,
} from './types';

export const contentAtom = atom<string>('');
export const knotHelpedHireAtom = atom<string | undefined>(undefined);
export const ratingAtom = atom<number>(0);

export const isValidRateVendorAtom = atom<boolean>((get) =>
	Boolean(get(contentAtom) && get(knotHelpedHireAtom) && get(ratingAtom) > 0),
);

export const uploadedMediaAtom = atom<UploadedMediaState[]>([]);

const costFormat = new Intl.NumberFormat('en-US', {
	style: 'currency',
	currency: 'USD',
	minimumFractionDigits: 0,
	maximumFractionDigits: 0,
});
export const costValueAtom = atom<number | undefined>(undefined);
export const costAtom = atom<string, string>(
	(get) => {
		const value = get(costValueAtom);
		if (value === undefined) return '';
		return costFormat.format(value);
	},
	(get, set, newCost) => {
		const costAsNumber = parseInt(newCost.replace(/\D/g, ''), 10);
		const cost = Number.isNaN(costAsNumber) ? undefined : costAsNumber;
		set(costValueAtom, cost);
	},
);

const guestCountFormat = new Intl.NumberFormat('en-US');
export const guestCountValueAtom = atom<number | undefined>(undefined);
export const guestCountAtom = atom<string, string>(
	(get) => {
		const value = get(guestCountValueAtom);
		if (value === undefined) return '';
		return guestCountFormat.format(value);
	},
	(get, set, newCost) => {
		const guestCountAsNumber = parseInt(newCost.replace(/\D/g, ''), 10);
		const guestCount = Number.isNaN(guestCountAsNumber)
			? undefined
			: guestCountAsNumber;
		set(guestCountValueAtom, guestCount);
	},
);

export const firstNameAtom = atom<string>('');
export const lastNameAtom = atom<string>('');
export const emailAtom = atom<string>('');

export const isValidAuthenticatedUserInfoAtom = atom<boolean>((get) =>
	Boolean(get(firstNameAtom) && get(lastNameAtom)),
);
export const isValidUnauthenticatedUserInfoAtom = atom<boolean>((get) =>
	Boolean(get(isValidAuthenticatedUserInfoAtom) && get(emailAtom)),
);

export const previouslySubmittedMediaAtom = atom<Gallery[]>([]);

export const getReviewFormSubmissionAtom = atom<ReviewFormSubmission>((get) => {
	const cost = get(costValueAtom);
	const guestCount = get(guestCountValueAtom);
	const knotHelpedHire = get(knotHelpedHireAtom) === 'Yes';
	const gallery: ReviewPhoto[] = get(uploadedMediaAtom)
		.filter((media) => !media.isErrored)
		.map((filteredMedia) => ({
			typeCode: 'PHOTO',
			sourceId: filteredMedia.serverReturnedMediaId,
			source: {
				application: 'theknot',
			},
		}));
	const existingGallery: ReviewPhoto[] = get(previouslySubmittedMediaAtom).map(
		(media) => ({
			typeCode: 'PHOTO',
			sourceId: media.sourceId,
			source: {
				application: 'theknot',
			},
		}),
	);
	const budget: ReviewFormSubmission['budget'] = {};
	if (cost) budget.cost = cost;
	if (guestCount) budget.guestCount = guestCount;

	return {
		budget,
		gallery: [...gallery, ...existingGallery],
		email: get(emailAtom),
		firstName: get(firstNameAtom),
		lastName: get(lastNameAtom),
		vendorHired: knotHelpedHire,
		vendorFoundOnTK: knotHelpedHire,
		rating: get(ratingAtom),
		content: get(contentAtom),
	};
});
