import { compose } from '@xo-union/react-css-modules';
import { Carousel } from '@xo-union/ui-carousel';
import { useAtomValue } from 'jotai';
import React, { Dispatch, FC, SetStateAction, useMemo } from 'react';
import { previouslySubmittedMediaAtom } from '../../atoms';
import { ReviewFormInteraction, UploadedMediaState } from '../../types';
import PhotoUpload from '../photo-upload';
import { PreviouslyUploadedPhoto } from '../previously-uploaded-photo';
import { UploadedPhoto } from '../uploaded-photo';
import Styles from './styles.scss';

export interface MobileUploadProps {
	handleTrackReviewFormInteraction: (action: ReviewFormInteraction) => void;
	memberId?: string;
	setUploadedMedia: Dispatch<SetStateAction<UploadedMediaState[]>>;
	uploadedMedia: UploadedMediaState[];
	vendorId?: string;
}

const MobileUpload: FC<MobileUploadProps> = (props) => {
	const {
		handleTrackReviewFormInteraction,
		memberId,
		setUploadedMedia,
		uploadedMedia,
		vendorId,
	} = props;
	const previouslySubmittedMedia = useAtomValue(previouslySubmittedMediaAtom);

	const carouselStyles = compose({
		relativeContainer: Styles.relativeContainer,
		slideContainer: Styles.slideContainer,
	});

	const validMedia = useMemo(() => {
		return uploadedMedia.filter((media) => media.isCorrectSize);
	}, [uploadedMedia]);

	return (
		<div className={Styles.uploadedPhotosContainer}>
			<Carousel
				alignment={() => 'left'}
				classes={carouselStyles}
				gutter={12}
				minSlideWidth={100}
				maxSlideWidth={100}
			>
				<PhotoUpload
					setUploadedMedia={setUploadedMedia}
					handleTrackReviewFormInteraction={handleTrackReviewFormInteraction}
				/>
				{previouslySubmittedMedia.map((photo, idx) => (
					<PreviouslyUploadedPhoto
						key={photo.sourceId}
						photo={photo}
						handleTrackReviewFormInteraction={handleTrackReviewFormInteraction}
						index={idx}
					/>
				))}
				{validMedia.reverse().map((media) => (
					<UploadedPhoto
						key={media.id}
						media={media}
						setUploadedMedia={setUploadedMedia}
						handleTrackReviewFormInteraction={handleTrackReviewFormInteraction}
						memberId={memberId}
						vendorId={vendorId}
					/>
				))}
			</Carousel>
		</div>
	);
};

export { MobileUpload };
