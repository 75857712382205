import {
	lightboxNextImageAtom,
	lightboxPrevImageAtom,
} from '@components/lightbox/atoms';
import { useTrackLightbox } from '@components/lightbox/hooks/use-track-lightbox';
import { useSetAtom } from 'jotai';
import { useCallback, useEffect } from 'react';

export const useLightboxKeydown = () => {
	const nextImage = useSetAtom(lightboxNextImageAtom);
	const prevImage = useSetAtom(lightboxPrevImageAtom);
	const trackLightbox = useTrackLightbox();

	const keydown = useCallback(() => {
		return (event: KeyboardEvent) => {
			switch (event.key) {
				case 'ArrowLeft': {
					prevImage(() => trackLightbox('clicked left'));
					break;
				}

				case 'ArrowRight': {
					nextImage(() => trackLightbox('clicked right'));
					break;
				}
				default:
					break;
			}
		};
	}, [nextImage, prevImage, trackLightbox]);

	useEffect(() => {
		const keydownEvent = keydown();
		window.addEventListener('keydown', keydownEvent);
		return () => {
			window.removeEventListener('keydown', keydownEvent);
		};
	}, [keydown]);
};
