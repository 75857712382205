import { mediaApiUrl } from '@settings';
import Icon from '@xo-union/tk-component-icons';
import { useSetAtom } from 'jotai';
import React, { FC } from 'react';
import { previouslySubmittedMediaAtom } from '../../atoms';
import { Gallery, ReviewFormInteraction } from '../../types';
import Styles from './styles.scss';

export interface PreviouslyUploadedPhotoProps {
	handleTrackReviewFormInteraction: (action: ReviewFormInteraction) => void;
	index: number;
	photo: Gallery;
}

const PreviouslyUploadedPhoto: FC<PreviouslyUploadedPhotoProps> = (props) => {
	const { handleTrackReviewFormInteraction, index, photo } = props;
	const setPreviouslySubmittedMedia = useSetAtom(previouslySubmittedMediaAtom);

	const handleDelete = () => {
		handleTrackReviewFormInteraction('remove media');
		setPreviouslySubmittedMedia((prevState) => {
			const nextState = prevState.slice();
			const remainingMedia = nextState.filter(
				(r) => r.sourceId !== photo.sourceId,
			);
			return remainingMedia;
		});
	};

	return (
		<div className={Styles.container}>
			<button
				className={Styles.closeButton}
				onClick={handleDelete}
				type="button"
				aria-label="Close Button"
			>
				<Icon name="close" size="md" />
			</button>
			<img
				className={Styles.image}
				src={`${mediaApiUrl}${photo.sourceId}~rs_100.100`}
				alt={`Previously uploaded media item ${index}`}
			/>
		</div>
	);
};

export { PreviouslyUploadedPhoto };
