import { useAppSelector } from '@redux/hooks';
import { Column } from '@xo-union/tk-component-grid';
import classnames from 'classnames';
import React from 'react';
import { Decorated } from 'types/vendor';
import InlineRfqModal from '../../../../pages/Storefront/containers/InlineRFQ/components/InlineRfqModal/InlineRfqModal';
import { useInlineRfqForm } from '../../../../pages/Storefront/containers/InlineRFQ/hooks/useInlineRfqForm/useInlineRfqForm';
import RFQButton from '../../../../pages/Storefront/containers/cta_box/RfqButton';
import FavoritesButton from '../../../../pages/Storefront/containers/vendor_info/components/favorites_button';
import Styles from './Footer.scss';

const Footer = () => {
	const vendor = useAppSelector((state) => state.vendor.vendor as Decorated);
	const membership = useAppSelector((state) => state.membership);

	const formContext = useInlineRfqForm({
		initiator: 'photo gallery',
	});

	return (
		<>
			<InlineRfqModal context={formContext} isModalOnModal={true} />
			<div className={Styles.footer} role="presentation">
				<div className={classnames(Styles.buttonsContainer)}>
					<Column xs="6" className={Styles.buttonColumns}>
						<FavoritesButton
							expanded
							membership={membership}
							vendor={vendor}
							className={Styles.favButton}
						/>
					</Column>
					<Column xs="6" className={Styles.buttonColumns}>
						<RFQButton
							size="md"
							label="Request Quote"
							handleCTAClick={formContext.openModal}
							htmlId="lightbox-footer-rfq-button"
						/>
					</Column>
				</div>
			</div>
		</>
	);
};

export default Footer;
