import {
	postAuthedStorefrontReview,
	postUnauthedStorefrontReview,
	updateReview,
} from '@api/reviews';
import useAnalyticsContext from '@hooks/useAnalyticsContext';
import { identityApi } from '@settings';
import queryString from 'query-string';
import { useCallback, useReducer } from 'react';
import VendorAnalytics from '../../../../../../decorators/vendorAnalytics';
import { initialState, reducer } from '../../reducer';
import {
	AdditionalInteractionProperties,
	ReducerState,
	ReviewFormInteraction,
	ReviewFormSubmission,
} from '../../types';

export interface ReviewModalSubmitResult {
	newReviewId?: string;
	errored?: boolean;
}

export type HandleSubmitWithSignUpOrLogin = (
	isLoggedIn: boolean,
	email: string,
	submitReview: () => void,
	openLogInModal: () => void,
	signUpUser: () => Promise<void>,
) => Promise<void>;

export type HandleLogInModalClosed = (
	isLoggedIn: boolean,
	setModalClosed: () => void,
	submitReview: () => void,
) => void;

interface ReviewFunctions {
	handleBack: () => void;
	handleNext: () => void;
	handleResetForm: () => void;
	handleSubmit: (
		vendorId: string,
		reviewFormSubmission: ReviewFormSubmission,
		token?: string,
		isLoggedIn?: boolean,
		reviewId?: string,
	) => Promise<ReviewModalSubmitResult | undefined>;
	handleTrackReviewFormInteraction: (
		action: ReviewFormInteraction | null,
		additionalProperties?: AdditionalInteractionProperties,
		eventNameOverride?: string,
	) => void;
	handleSubmitWithSignUpOrLogin: HandleSubmitWithSignUpOrLogin;
	handleLogInModalClosed: HandleLogInModalClosed;
}

type UseReviewModalStepsReturnValues = [ReducerState, ReviewFunctions];

const reviewStepNames = new Map<number, string>([
	[1, 'Rate Vendor'],
	[2, 'Photos and Budget'],
	[3, 'User Info'],
]);

const useReviewModalSteps = (
	vendor: Vendor.Decorated,
	init: Partial<ReducerState> = {},
	pageType?: string,
	isFromTypeahead?: boolean,
): UseReviewModalStepsReturnValues => {
	const [state, dispatch] = useReducer(reducer, { ...initialState, ...init });
	const { track } = useAnalyticsContext();

	const handleTrackReviewFormInteraction = useCallback(
		(
			action: ReviewFormInteraction | null,
			additionalProperties: AdditionalInteractionProperties = { memberId: '' },
			eventNameOverride?: string,
		) => {
			if (action === 'dismiss' && state.step > 3) return;

			const vendorProps = isFromTypeahead
				? { vendorId: vendor.id, vendorName: vendor.name }
				: new VendorAnalytics(vendor).baseEventProps();
			const storefrontType =
				vendor.purchaseStatus === 'PAID'
					? 'paid storefront'
					: 'unpaid storefront';
			const sourcePage =
				pageType === 'review wedding vendors' ? 'Review Hub' : storefrontType;
			track({
				event: eventNameOverride ?? 'Review Form Interaction',
				properties: {
					...vendorProps,
					sourcePage,
					reviewStepName: reviewStepNames.get(state.step),
					action: action ?? null,
					...additionalProperties,
				},
			});
		},
		[state, track, vendor],
	);

	const handleBack = useCallback(() => {
		if (state.step === 1) {
			return;
		}
		handleTrackReviewFormInteraction('back');
		dispatch({ type: 'back', payload: state });
	}, [dispatch, state]);

	const handleNext = useCallback(() => {
		if (state.step > 2) return;
		handleTrackReviewFormInteraction('next');
		dispatch({ type: 'next', payload: state });
	}, [dispatch, state]);

	const handleResetForm = useCallback(() => {
		dispatch({ type: 'reset' });
	}, [dispatch, state]);

	const oneReviewPerStorefrontErrorMessages = [
		'Each member may only have one review per storefront',
		'Each reviewer may only have one review per storefront',
	];
	const isOneReviewPerStorefrontError = ({
		status,
		message,
	}: { status: number; message: string }) =>
		status === 400 && oneReviewPerStorefrontErrorMessages.includes(message);

	const handleSubmit = async (
		vendorId: string,
		reviewFormSubmission: ReviewFormSubmission,
		token: string,
		isLoggedIn: boolean,
		reviewId?: string,
	) => {
		if (state.step !== 3) return;

		dispatch({ type: 'submit' });

		if (isLoggedIn && pageType === 'review wedding vendors' && reviewId) {
			try {
				const response = await updateReview(
					vendorId,
					reviewFormSubmission,
					token,
					reviewId,
				);
				if (response?.ok)
					dispatch({ type: 'submission-success', payload: { isLoggedIn } });
				else {
					const json = await response.json();
					const submissionError = isOneReviewPerStorefrontError({
						status: response.status,
						message: json.message,
					})
						? 'already-reviewed'
						: 'default';
					dispatch({ type: 'submission-error', payload: { submissionError } });
					return { errored: true };
				}
			} catch (error) {
				dispatch({
					type: 'submission-error',
					payload: { submissionError: 'default' },
				});
				return { errored: true };
			}
			return;
		}

		const postStorefrontReview = () =>
			isLoggedIn
				? postAuthedStorefrontReview(vendorId, reviewFormSubmission, token)
				: postUnauthedStorefrontReview(vendorId, reviewFormSubmission);
		try {
			const response = await postStorefrontReview();
			if (response?.ok) {
				dispatch({ type: 'submission-success', payload: { isLoggedIn } });
				const json = await response.json();
				return {
					newReviewId: json?.data?.id,
				};
			} else {
				const json = await response.json();
				const submissionError = isOneReviewPerStorefrontError({
					status: response.status,
					message: json.message,
				})
					? 'already-reviewed'
					: 'default';
				dispatch({ type: 'submission-error', payload: { submissionError } });
				return { errored: true };
			}
		} catch {
			dispatch({
				type: 'submission-error',
				payload: { submissionError: 'default' },
			});
			return { errored: true };
		}
	};

	const emailExists = useCallback(
		async (email: string): Promise<boolean> => {
			const query = queryString.stringify({
				email,
			});

			const url = `${identityApi}/member/exists?${query}`;
			const response = await fetch(url);

			return await response.json();
		},
		[identityApi],
	);

	const handleSubmitWithSignUpOrLogin = useCallback(
		async (
			isLoggedIn: boolean,
			email: string,
			submitReview: () => void,
			openLogInModal: () => void,
			signUpUser: () => Promise<void>,
		): Promise<void> => {
			if (isLoggedIn) {
				submitReview();
				return;
			}

			dispatch({ type: 'submit' });
			const exists = await emailExists(email);

			if (exists) {
				openLogInModal();
			} else {
				await signUpUser();
				submitReview();
			}
		},
		[dispatch, emailExists],
	);

	const handleLogInModalClosed = useCallback(
		(
			isLoggedIn: boolean,
			setModalClosed: () => void,
			submitReview: () => void,
		): void => {
			setModalClosed();
			if (isLoggedIn) {
				submitReview();
			} else {
				dispatch({ type: 'submission-cancelled' });
			}
		},
		[dispatch],
	);

	return [
		state,
		{
			handleBack,
			handleNext,
			handleResetForm,
			handleSubmit,
			handleTrackReviewFormInteraction,
			handleSubmitWithSignUpOrLogin,
			handleLogInModalClosed,
		},
	];
};

export { useReviewModalSteps };
