export const roundReviewRating = (number) => {
	const parsedNum = parseFloat(number);
	if (parsedNum === 0) {
		return 0;
	}

	const roundedNumer = Math.round(parsedNum * 10) / 10;

	return parseFloat(roundedNumer.toFixed(1));
};
