import { Lightbox } from '@components/lightbox/Lightbox';
import { isReviewsLightboxAtom } from '@components/lightbox/atoms';
import { useAppSelector } from '@redux/hooks';
import { useAtomValue } from 'jotai';
import React from 'react';
import { MobileReview } from './mobile-review';

const ReviewLightbox = () => {
	const isMobile = useAppSelector((state) => state.viewport.isMobile);
	const isReviewsLightbox = useAtomValue(isReviewsLightboxAtom);

	if (!isReviewsLightbox) {
		return null;
	}

	return (
		<>
			{isMobile && <MobileReview />}
			{/*
      This needs removed in the future. Lightbox exists at a page level because of jotai.
      Having it nested results in multiple being opened.
      Added the isReviews to short circuit for now
      */}
			{!isMobile && <Lightbox />}
		</>
	);
};

export { ReviewLightbox };
