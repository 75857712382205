import classNames from 'classnames';
import React, { memo } from 'react';
import { Media as VendorMedia, PhotoMedia } from 'types/vendor';
import MediaComponent from '../../../../pages/Storefront/components/Media';
import Playable, {
	PlayableRenderProps,
} from '../../../../pages/Storefront/components/Media/playable';
import Styles from './media-container.scss';

export interface MediaProps {
	media: VendorMedia;
	totalCount: number;
	index: number;
	visible: boolean;
	isSingleItem: boolean;
}

export const landscape = { width: 1016, height: 678 } as const;
export const portrait = { width: 513, height: 770 } as const;

const getDimensionProps = (media: VendorMedia) => {
	if (media.mediaType === 'VIDEO' || media.mediaType === '360TOUR') {
		return landscape;
	}
	const photoMedia = media as PhotoMedia;

	if (!photoMedia.height || !photoMedia.width) {
		return landscape;
	}

	const height = Number.parseInt(photoMedia.height, 10);
	const width = Number.parseInt(photoMedia.width, 10);

	return width >= height ? landscape : portrait;
};

export const MediaContainer = memo(
	({ media, index, visible, isSingleItem }: MediaProps) => {
		const dimensionProps = getDimensionProps(media);
		const hasDimensions = 'height' in media && 'width' in media;
		return (
			<>
				<div
					className={classNames(Styles.mediaContainerWrapper, 'bone', {
						[Styles.hidden]: !visible,
						[Styles.mediaUnknownDimensions]: !hasDimensions,
					})}
					data-testid={`media-container-wrapper-${index}`}
				>
					<div className={Styles.mediaContainer}>
						<Playable
							media={media}
							render={({ playMedia }: PlayableRenderProps) => {
								const { mediaType } = media;
								if (
									isSingleItem &&
									(mediaType === '360TOUR' || mediaType === 'VIDEO')
								) {
									playMedia();
								}
								return (
									<MediaComponent
										canPlay
										smartCrop
										mediaItem={media}
										lazy={index > 4}
										{...dimensionProps}
									/>
								);
							}}
						/>
					</div>
				</div>
			</>
		);
	},
);
