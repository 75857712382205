import {
	closeLightboxAtom,
	lightboxIndexAtom,
	lightboxMediaAtom,
	lightboxOpenAtom,
} from '@components/lightbox/atoms';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import React from 'react';
import LightboxModal from '../../../pages/Storefront/containers/StorefrontGallery/mobile_gallery/components/lightbox_modal/LightboxModal';

export const MobileReview = () => {
	const closeLightbox = useSetAtom(closeLightboxAtom);
	const lightboxOpen = useAtomValue(lightboxOpenAtom);
	const media = useAtomValue(lightboxMediaAtom);
	const [index, setIndex] = useAtom(lightboxIndexAtom);

	return (
		<>
			{lightboxOpen && (
				<LightboxModal
					carouselMedia={media}
					mediaType="PHOTO"
					overviewIndex={index}
					setActiveImageIndex={setIndex}
					isModalOpen={lightboxOpen}
					toggleModal={closeLightbox}
				/>
			)}
		</>
	);
};
