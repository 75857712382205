import { ReducerAction, ReducerState } from '../types';

const initialState: ReducerState = {
	step: 1,
	titleText: 'Vendor Name Goes Here',
	isSubmitting: false,
};

const reducer = (state: ReducerState, action: ReducerAction): ReducerState => {
	switch (action.type) {
		case 'next':
			return {
				...state,
				step: state.step + 1,
				titleText: action.payload.titleText,
			};

		case 'back':
			return {
				...state,
				step: state.step - 1,
				titleText: action.payload.titleText,
			};

		case 'submit':
			return {
				...state,
				isSubmitting: true,
			};

		case 'submission-cancelled':
			return {
				...state,
				isSubmitting: false,
			};

		case 'submission-success':
			return {
				...state,
				step: state.step + 1,
				titleText: action.payload.isLoggedIn
					? 'Many thanks!'
					: 'Check your email',
				isSubmitting: false,
			};

		case 'submission-error':
			return {
				...state,
				...action.payload,
				step: 5,
				titleText: "We're sorry",
				isSubmitting: false,
			};

		case 'reset':
			return initialState;

		default:
			return initialState;
	}
};

export { initialState, reducer };
